<template>
  <div class="content">
    <img src="../assets/subIcon.svg" alt="" class="icon" />
    <span style="">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
};
</script>

<style lang="scss" scoped>
.content {
  margin: 16px 0 30px 0;
  span {
    font-size: 16px;
    color: #545454;
  }
}
.icon {
  width: 10px;
  height: 16px;
  margin: 0 15px 0 6px;
  position: relative;
  top: 2px;
}</style
>>
